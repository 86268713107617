<template>
  <WrapperAuth>
    <template #content>
      <v-card-title>{{ $t('login.recoveryPassword') }}</v-card-title>
      <v-card-subtitle>{{ $t('login.enterEmail') }}</v-card-subtitle>
      <v-card-text>
        <v-text-field
          color="secondary"
          :label="$t('login.login')"
          prepend-icon="mdi-account"
          dense
          v-model="login"
          outlined
          :rules="rules"
        ></v-text-field>
      </v-card-text>
    </template>
    <template #actions>
      <v-card-actions class="d-flex justify-space-between">
        <BaseBtn
          title="назад"
          text
          color="primary"
          @click="$router.back()"
        />
        <BaseBtn
          :title="$t('login.recovery')"
          :disabled="!valid"
          @click="resetPassword"
          color="primary"
          small
        />
      </v-card-actions>
    </template>
  </WrapperAuth>
</template>

<script>
import WrapperAuth from '@/components/views/mobile/auth/WrapperAuth'
import BaseBtn from '@/components/base/UI/BaseBtn'

export default {
  name: 'RecoveryPassword',
  components: { WrapperAuth, BaseBtn },
  data () {
    return {
      login: null,
      valid: false,
      rules: [
        value => {
          if (!value) {
            return 'Поле обязательно'
          }
          const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          this.valid = pattern.test(value)
          return pattern.test(value) || 'Неверный email'
        }
      ]
    }
  },
  methods: {
    async resetPassword () {
      const data = {
        data: { email: this.login }
      }
      const response = await this.$store.dispatch('api/post', { url: '/reset-password', data }, { root: true })

      if (response.success) {
        await this.$store.dispatch('systemMessages/success', 'Пароль сброшен, проверьте почту')
        await this.$router.push({ path: '/login' })
      }
      return true
    }
  }
}
</script>

<style scoped>

</style>
